import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {Wrapper, Title, Bracket, Round, GameFourTeams, LowerRankTeam, HigherRankTeam, GameInfo, RoundTitle, Date  } from './PlayoffBracket.styled'
import jsonData from '../../Basketball/Playoffs.json';

const PlayoffBracket  = () => {
  const [bracketData, setBracketData] = useState([]);

  const location = useLocation(); // Get the current path

  useEffect(() => {
      if (location.pathname.includes("SaturdayPlayoffs")) {
          setBracketData(jsonData[0].Saturday_Playoffs);
      } else if (location.pathname.includes("SundayPlayoffs")) {
          setBracketData(jsonData[0].Sunday_Playoffs);
      }
  }, [location]);


    return ( <div>
      <Wrapper>
      <Title>{location.pathname.includes("Saturday") ? "Saturday Playoffs" : "Sunday Playoffs"}</Title>
        <Bracket>
       
      {bracketData.map((round, roundIndex) => (
        <Round key={roundIndex} roundIndex={roundIndex}>
          <RoundTitle>{round.week}</RoundTitle>
          <Date>{round.date}</Date>
          {roundIndex !== 0}
          {round.games.map((game, gameIndex) => (
        <GameFourTeams key={gameIndex} roundIndex={roundIndex}>
          <HigherRankTeam style={{ fontWeight: game.score1 > game.score2 ? 'bold' : 'normal' }}>
            {game.team1}
          </HigherRankTeam>
          <GameInfo key={roundIndex} roundIndex={roundIndex}>{game.time}</GameInfo>
          <LowerRankTeam key={roundIndex} roundIndex={roundIndex} style={{ fontWeight: game.score2 > game.score1 ? 'bold' : 'normal' }}>
            {game.team2}
          </LowerRankTeam>
        </GameFourTeams>
          ))}
        </Round>
      ))}
    </Bracket>
    </Wrapper>
    </div>
    
      );
    
};

export default PlayoffBracket;